/* global axios, dom, i18n, swal */

import currentTicketInput from './currentTicketInput.js'
import summaryUpdate from './summaryUpdate.js'
import setValidations from '../../lib/setValidations.js'
import submission from './submission.js'

const t = i18n.t


const lockButton = () => {
  const couponBtn = document.querySelector('[type="coupon"]')
  dom.addClass(couponBtn, 'c-btn--loading')
  dom.removeClass(couponBtn, 'js-remove-coupon')
  dom.removeClass(couponBtn, 'c-btn--secondary')
  couponBtn.disabled = true
  couponBtn.innerHTML = t('loading')
}

const unlockButton = () => {
  const couponBtn = document.querySelector('[type="coupon"]')
  dom.removeClass(couponBtn, 'c-btn--loading')
  dom.removeClass(couponBtn, 'js-remove-coupon')
  dom.removeClass(couponBtn, 'c-btn--secondary')
  couponBtn.disabled = false
  couponBtn.innerHTML = t('validate_coupon')
  summaryUpdate()
}

const removeButton = () => {
  const couponBtn = document.querySelector('[type="coupon"]')
  dom.removeClass(couponBtn, 'c-btn--loading')
  dom.addClass(couponBtn, 'js-remove-coupon')
  dom.addClass(couponBtn, 'c-btn--secondary')
  couponBtn.disabled = false
  couponBtn.innerHTML = t('remove_coupon')
  summaryUpdate()
}

const showOrHideGroup = (show, groupId = null) => {

  const orderGroupsEl = document.querySelectorAll('.order_group')
  orderGroupsEl.forEach(element => {
    element.style.display = show ? 'block' : 'none';
  });

  const elementsOptionsGroups = document.querySelectorAll('.option-group')
  elementsOptionsGroups.forEach(element => {
    element.style.display = 'none'
  })

  if (show) {
    document.getElementById('field_ticket_order_group').value = groupId
    setValidations('.c-form--ticket-choices', submission.constraintsSpeakers, submission.onValidationSuccess)

    const elementGroups = document.querySelectorAll(`.option-ticket-${groupId}`)

    elementGroups.forEach(elementGroup => {
      elementGroup.style.display = 'block'
    })
  } else {
    document.getElementById('field_ticket_order_group').value = ""
    setValidations('.c-form--ticket-choices', submission.constraints, submission.onValidationSuccess)
    document.querySelectorAll('.option-group').forEach(div => {
      div.style.display = 'none'
    })

    document.querySelectorAll('.option-ticket').forEach(input => {
      input.checked = true
    })
  }
}

const showOrHideImageRights = () => {

  const group_element = document.querySelector('#field_ticket_order_group')
  const has_group = group_element && group_element != null && group_element != undefined

  const show = has_group && group_element.value != "" && group_element.value != null && group_element.value != undefined

  if (show) {
    const groupsElements = document.querySelectorAll('.has_group')

    groupsElements.forEach(group => {
      group.style.display = 'block'
    })

    setValidations('.c-form--ticket-choices', submission.constraintsSpeakers, submission.onValidationSuccess)
  }
}

const removeCoupon = () => {

  const stateEl = document.querySelector('.js-coupon-data')
  const couponBtn = document.querySelector('[type="coupon"]')
  const couponInput = document.querySelector('[name="ticket_order[coupon]"]')
  
  if (!couponBtn || !dom.hasClass(couponBtn, 'js-remove-coupon')) {
    return
  }
  
  showOrHideGroup(false)
  couponInput.value = ''

  dom.setData(stateEl, { applied: 'false' })

  unlockButton()
}

const onValidateCoupon = async function(event) {
  event.preventDefault()

  const couponBtn = document.querySelector('[type="coupon"]')
  const couponInput = document.querySelector('[name="ticket_order[coupon]"]')
  const stateEl = document.querySelector('.js-coupon-data')
  const couponUrl = (code) => `/helper_apis/ticket_coupons/${code}`

  if (dom.hasClass(couponBtn, 'c-btn--loading')) {
    return
  }

  if (dom.hasClass(couponBtn, 'js-remove-coupon')) {
    removeCoupon()
    return
  }

  lockButton()

  const code = couponInput.value
  couponInput.value = code.toUpperCase()
  const url = couponUrl(code)

  try {
    const response = await axios.get(url, {
      params: {
        ticket_id: currentTicketInput().value
      }
    })

    if (!response.data.success) {
      if (response.data.name)
      {
        swal({
          title: t('error'),
          type: 'warning',
          text: t('invalid_coupon_for_ticket', { ticket: response.data.name }),
          showCloseButton: true,
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonText: 'OK'
        })
      }
      else
      {
        swal({
          title: t('error'),
          type: 'warning',
          text: t('invalid_or_out_of_uses'),
          showCloseButton: true,
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonText: 'OK'
        })
      }

      unlockButton()
      return
    }

    const countrySpecialCondition = document.querySelector('#special_condition')
    
    if (countrySpecialCondition) {
      countrySpecialCondition.checked = false
    }

    const data = Object.assign(response.data, {
      applied: true,
      success: false
    })

    dom.setData(stateEl, data)

    removeButton()

    if (response.data.ticket_group) {
      showOrHideGroup(true, response.data.ticket_id)

      const speakerInput = document.getElementById(`ticket_option_${response.data.ticket_id}`)

      document.getElementById(`ticketeer__option_${response.data.ticket_id}`)
        .style.display = 'block'

      if (speakerInput) {
        speakerInput.checked = true
      }
    }

    showOrHideImageRights()
  } catch(err) {
    console.error(err)

    swal({
      title: t('error'),
      type: 'warning',
      text: t('unknown_error'),
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: 'OK'
    })

    unlockButton()
    return
  }
}

const hasCoupon = () => {
  const stateEl = document.querySelector('.js-coupon-data')
  return dom.allData(stateEl).applied === 'true'
}

const init = () => {
  dom.ready(() => {
    const couponBtn = document.querySelector('[type="coupon"]')
    if (couponBtn) {
      couponBtn.addEventListener('click', onValidateCoupon)
    }

    showOrHideImageRights()
  })
}

export { 
  init, 
  removeCoupon,
  hasCoupon
}

